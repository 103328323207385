import Cookies from "js-cookie";

export const state = () => ( {

    //로그인 유저
    loginUser: {

        userId      : "",
        userName    : "",
        userOid     : "",
        userEmail   : "",
        userRoleOids: [],

        userBirthDay    : "",
        userType        : "",
        orgName         : "",
        orgCode         : "",

        isManager: false,
        isNoDuplicateCheck: false,
        loginLastDate     : "",
        dormantStartDate  : "",
        isSelfCerted      : false,

        loginAuditOid     : "",
        lastLoginUserIp   : "",

        // 구글 워크스페이스
        googleWorkspaceId     : "",
        googleOrgUnitId       : "",
        isSameOrgUnit         : false,
        googleUserInfo        : {},
        googleInsertStatusFlag: "",
        isGoogleApiConnected  : true,

        // 중복 로그인 설정 여부
        isDuplicateLoginCheck : false,
    }
} )

export const mutations = {

	// 로그인하고 나면 로그인 유저로 loginUser를 맞춰준다
	LOGIN_USER( state, loginUser ) {
		state.loginUser = loginUser;
	},

	SET_LOGIN_AUDIT_OID( state , loginAuditOid ) {

		state.loginUser.loginAuditOid = loginAuditOid;
	},

  LOGOUT_USER( state ) {
    state.loginUser = {
      userId      : "",
      userName    : "",
      userOid     : "",
      userEmail   : "",
      userRoleOids: [],

      userBirthDay    : "",
      userType        : "",
      orgName         : "",
      orgCode         : "",

      isManager: false,
      isNoDuplicateCheck: false,
      loginLastDate     : "",
      dormantStartDate  : "",
      isSelfCerted      : false,

      loginAuditOid     : "",
      lastLoginUserIp   : "",

      // 구글 워크스페이스
      googleWorkspaceId     : "",
      googleOrgUnitId       : "",
      isSameOrgUnit         : false,
      googleUserInfo        : {},
      googleInsertStatusFlag: "",
      isGoogleApiConnected  : true,

      // 중복 로그인 설정 여부
      isDuplicateLoginCheck : false,
    }
  }

}

export const actions = {

  logout( { commit } ) {

    const URL_LOGOUT = "/api/user/v1/portalUserLogoutApi_logout";
    return $nuxt.$axios.post( URL_LOGOUT, {} ).then( res => {

        commit( "LOGOUT_USER" );

        sessionStorage.clear();

        // KWE_USERID, KWE_LOGIN_TYPE 쿠키 삭제
        // 사파리에서 브라우저에 쿠키가 캐싱되어있는 경우가 있어
        // middleware에서 계속 있다고 인식하기 때문에 아래와 같이 처리하였습니다.
        const systemProfile = process.env.SYSTEM_PROFILE;
        let domain = "";

        if ( "REAL" === systemProfile ) {
          domain = "gwedu.go.kr";
        }
        else if ( "DEV" === systemProfile ) {
          domain = "testremarkable.com";
        }
        else if ( "LOCAL" === systemProfile ) {
          domain = "localhost";
        }

        Cookies.remove( 'KWE_USERID', { path : '/', domain : domain } );
        Cookies.remove( 'KWE_LOGIN_TYPE', { path : '/', domain : domain } );

        Cookies.remove( "NSI" );
        Cookies.remove( "LAO" );
    } );

  },

	loginUser( { commit }, loginUser ) {

		commit( "LOGIN_USER", loginUser );
	},

	setLoginAuditOid ( { commit }, loginAuditOid ) {

		commit( "SET_LOGIN_AUDIT_OID", loginAuditOid );
	},



}

export const getters = {

	loginUserInfo( state ) {

		if ( $nuxt.$validate.isEmpty( state.loginUser ) ) {
			$nuxt.$store.dispatch( "common/login/loginUser" );
		}

		return state.loginUser;
	}
}
