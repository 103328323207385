import { PORTAL_CONSTANT } from '~/constant/site/portal/portalConstant';

const URL_GET_ORG = 'api/user/v1/portalUserOrgCodeApi_get';
const URL_EXCEL_FILE_TO_JSON = '/api/manager/v1/portalManagerExcelApi_excelFileToJson';		// 업로드한 엑셀 파일에서 json을 가져옵니다.

/**
 * 구글워크스페이스 관련 공통 스크립트
 *
 * @version 1.0 2023.02.17 신규생성
 */
const portalGoogleWorkspace = {

  /**
   * 포털 기관의 oid로 PORTAL_ORG의 구글워크스페이스 조직 정보를 가져옵니다.
   *
   * @param {string}orgOid
   * @return {Promise<{workspaceOrgName: string, workspaceOrgUnitPath: string, workspaceOrgCode: string}>}
   */
  async getWorkspaceOrgInfoFromPortalOrg ( orgOid ) {

    if ( $nuxt.$validate.isEmpty( orgOid ) ) {
      const loginUSer = $nuxt.$store.state.common.login.loginUser;

      orgOid = loginUSer?.orgCode;
    }

    const workspaceOrgInfo = {
      workspaceOrgCode    : '',
      workspaceOrgName    : '',
      workspaceOrgUnitPath: '',
    };

    if ( $nuxt.$validate.isEmpty( orgOid ) ) {
      return workspaceOrgInfo;
    }

    let portalOrg = {};

    await $nuxt.$axios.post( URL_GET_ORG, { oid: orgOid } ).then( res => {

      if ( $nuxt.$validate.isEmpty( res.data ) ) {
        return workspaceOrgInfo;
      }

      portalOrg = res.data;
    } );

    return {
      workspaceOrgCode    : portalOrg.workspaceOrgCode,
      workspaceOrgName    : portalOrg.workspaceOrgName,
      workspaceOrgUnitPath: portalOrg.workspaceOrgUnitPath,
    };
  },

  /**
   * 구글워크스페이스 소속기관 이름을 반환합니다.
   *  orgUnitPath : /newportal/초등학교(E)/[E999]테스트초등학교/tea
   * @param {string}orgUnitPath
   * @return {string} ex ) [E999]테스트초등학교
   */
  getWorkspaceOrgName ( orgUnitPath ) {

    const orgUnitPathArray = orgUnitPath?.split( '/' );
    let lastNode = orgUnitPathArray?.pop();
    if ( PORTAL_CONSTANT.WORKSPACE_ORG_CONST.LAST_NODE.TEACHER.KEY === lastNode
      || PORTAL_CONSTANT.WORKSPACE_ORG_CONST.LAST_NODE.STUDENT.KEY === lastNode
      || PORTAL_CONSTANT.WORKSPACE_ORG_CONST.LAST_NODE.DEVICE.KEY === lastNode
    ) {
      lastNode = orgUnitPathArray.pop();
    }

    return lastNode?.split( ' ' ).pop();

  },

  /**
   * 구글워크스페이스 조직 코드를 반환합니다.
   * orgName : [E999]테스트초등학교
   *
   * @param {string}orgName
   * @return {*|string} ex ) E9999
   */
  getWorkspaceOrgCode ( orgName ) {

    if ( $nuxt.$validate.isEmpty( orgName ) ) {
      return '';
    }

    const workspaceOrgCode = orgName?.match( /\[(.*?)\]/g )?.map(
      str => str.slice( 1, -1 ) );

    return workspaceOrgCode?.pop();

  },

  /**
   * 구글 워크스페이스의 유저 타입을 반환합니다.
   *
   * @param userType
   */
  getGoogleUserType ( userType ) {

    return $nuxt.$validate.isNotEmpty(
      PORTAL_CONSTANT.GOOGLE_USER_TYPE[ userType ].KEY ) ?
      PORTAL_CONSTANT.GOOGLE_USER_TYPE[ userType ].KEY : 'NORMAL';
  },

  /**
   * 구글워크스페이스 계정 생성시 유저 이름을 반환합니다.
   * 이름 2자일때 성 + 명
   * 이름 3자이면 성  + 이름
   * 이름 4자이면 서엉 + 이름
   *
   * @param userName
   */
  getGoogleUserName ( userName ) {

    if ( $nuxt.$validate.isEmpty( userName ) ) {
      return {
        familyName: '',
        givenName : '',
      };
    }

    const nameLength = userName.length;
    let givenName = '';
    let familyName = '';

    if ( 4 === nameLength ) {
      familyName = userName.substring( 0, 2 );
      givenName = userName.substring( 2, nameLength );
    }
    else {
      familyName = userName.substring( 0, 1 );
      givenName = userName.substring( 1, nameLength );
    }

    return {
      familyName: familyName,
      givenName : givenName,
    };

  },


  /**==================================
   * 사용자 등록
   * ==================================
   */

  /**
   * 사용자 타입에 따른 워크스페이스 기관 경로 반환
   * @param userType
   * @return {{path: string, userType: (string|*)}|{path: string, userType: string}|string}
   */
  setOrgPathByUserType( userType ) {

    switch (userType) {
      case PORTAL_CONSTANT.WORKSPACE_ORG_CONST.LAST_NODE.TEACHER.ID_RULE :
        return {
          path: PORTAL_CONSTANT.WORKSPACE_ORG_CONST.LAST_NODE.TEACHER.PATH,
          userType: PORTAL_CONSTANT.WORKSPACE_USER_TYPE_RADIO.TEACHER.VALUE,
        };
      case PORTAL_CONSTANT.WORKSPACE_ORG_CONST.LAST_NODE.STUDENT.ID_RULE :
        return {
          path: PORTAL_CONSTANT.WORKSPACE_ORG_CONST.LAST_NODE.STUDENT.PATH,
          userType: PORTAL_CONSTANT.WORKSPACE_USER_TYPE_RADIO.STUDENT.VALUE,
        };
      case PORTAL_CONSTANT.WORKSPACE_ORG_CONST.LAST_NODE.DEVICE.ID_RULE :
        return {
          path: PORTAL_CONSTANT.WORKSPACE_ORG_CONST.LAST_NODE.DEVICE.PATH,
          userType: PORTAL_CONSTANT.WORKSPACE_USER_TYPE_RADIO.DEVICE.VALUE,
        };
      default :
        return '';
    }
  },

  /**
   * 기관코드(학교번호) 셋팅
   * @param path
   * @returns {string|*}
   */
  setSchoolCode( path ) {
    if ( $nuxt.$validate.isEmpty( path ) ) {
      return "";
    }
    const schoolName = path.split( '/' )?.reverse()?.[1];
    let schoolCode = schoolName?.split( ' ' )[0];
    schoolCode = schoolCode.replace( /[^0-9A-Z]/g, '' );
    schoolCode = $nuxt.$validate.isEmpty( schoolCode ) ? 'test' : schoolCode;
    return schoolCode;
  },

  /* 해당 기관의 유저 목록에서 마지막 번호를 가져온다. */
  async getLastUserNumber ( query ) {

    if ( $nuxt.$validate.isEmpty( query ) ) {
      return;
    }

    const param = {
      maxResult: 1,
      page     : 1,
      query    : query,
      orderBy  : 'EMAIL',
      sortOrder: 'DESCENDING',
    };

    try {
      const { data } = await $nuxt.$googleDirectory.getUsersList( param ); // 유저 리스트를 호출
      const { usersList: { users: userList } } = data;

      // 해당 길이가 0이면 형식에 맞는 회원 수가 없다는것을 의미합니다.
      if ( 0 === userList.length ) {
        return 0;
      }

      // 마지막 사용자의 번호입니다. 검색에서 잡힌 사용자의 메일 주소가 E001S22003@도메인 이였다면 003을 리턴합니다.
      const lastUserNumber = userList[ 0 ].primaryEmail.split( '@' ).shift().slice( -3 );

      // 만일 마지막 사용자의 아이디 번호가 999였다면, 0을 리턴합니다.
      if ( '999' === lastUserNumber ) {
        return 0;
      }

      return lastUserNumber;

    }
    catch ( e ) {
      // console.error( e.response );
      return 0;
    }
  },


  /**
   * 탭을 이동하면 값이 안맞는 경우가 있어 최종값을 다시한번 체크
   * @param userInfoList
   * @param serialNumber
   */
  findMaxSerialNumber ( userInfoList, serialNumber ) {

    let max = 0;
    if ( $nuxt.$validate.isNotEmpty( userInfoList ) ) {
      max = userInfoList.reduce(
        ( prev, current ) => ( prev.lastNo > current.lastNo ) ? prev : current ).lastNo;
    }

    serialNumber = this.paddingSerialNumber( serialNumber );

    // 값만 비교
    if ( max === serialNumber ) {
      serialNumber++;
    }

    return serialNumber;

  },

  /**
   * 전체 searialNumber는 220001 이런식이 되어야합니다
   * 앞으로 두자리는 년도 뒤에 3자리는 계정 순서
   *
   * @param serialNumber
   */
  paddingSerialNumber( serialNumber ) {

    if ( serialNumber.toString().length < 5 ) {
      //  사이즈 체크해서 사이즈가 맞지 않으면 채워줌
      serialNumber = $nuxt.$util.dateUtils.getYearBack2() + serialNumber.padStart( 3, '0' );
    }

    return serialNumber;
  },


  /**
   * 아이디 유효성 검사 문구
   * @param idAlert
   * @return {string|*}
   */
  idAlertMsg( idAlert ) {

    if ( $nuxt.$validate.isEmpty( idAlert ) ) {
      return '';
    }

    switch ( idAlert ) {

      case PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.INCOMPLETE.KEY :
        return PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.INCOMPLETE.VALUE;

      case PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.NEED_VALIDATION.KEY :
        return PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.NEED_VALIDATION.VALUE;

      case PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.DUPLICATION.KEY :
        return PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.DUPLICATION.VALUE;

      case PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_DOMAIN.KEY :
        return PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_DOMAIN.VALUE;

      case PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_ID_FORMAT.KEY :
        return PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_ID_FORMAT.VALUE;

      case PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.DUPLICATION_IN_LIST.KEY :
        return PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.DUPLICATION_IN_LIST.VALUE;

      case PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_PASSWORD_FORMAT.KEY :
        return PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_PASSWORD_FORMAT.VALUE;

      default :
        return PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.AVAILABLE.VALUE;

    }
  },

  /**
   * 비밀번호 유효성 검사 문구
   * @param passAlert
   * @return {string|*}
   */
  passAlertMsg( passAlert ) {

    return (PORTAL_CONSTANT.WORKSPACE_PASSWORD.VALIDATION.INCOMPLETE.KEY === passAlert)
      ? PORTAL_CONSTANT.WORKSPACE_PASSWORD.VALIDATION.INCOMPLETE.VALUE_10 :
      PORTAL_CONSTANT.WORKSPACE_PASSWORD.VALIDATION.AVAILABLE.VALUE;
  },






  /**==================================
   * 사용자 엑셀 일괄 등록
   * ==================================
   */

  /**
   * 구글워크스페이스 계정 일괄 등록 엑셀 다운로드
   */
  excelGoogleWorkspaceTemplateDownload () {

    $nuxt.$util.fileUtils.downloadTemplateFile( "applicationForm", "workspaceTemplate.xlsx" );
  },

  /**
   * 엑셀 파일 업로드 (무조건 단건으로 전송되어야 합니다. )
   * @param file
   * @param options
   * @return {Promise<{}>}
   */
  async getWorkspaceUserByExcel ( file, options = { limitCnt: 30 } ) {

    let result = {};

    if ( $nuxt.$validate.isEmpty( file ) ) {

      result.error = '파일이 업로드 되지 않았습니다.';
      return result;
    }

    // TheDropZone에서 넘어올 때 배열 형태로 넘어올 경우 첫번째 파일로
    if ( Array.isArray( file ) ) {
      file = file[0];
    }

    const param = {
      storageFileUid: file.storageFileUid,
      fileName      : file.fileName,
    };

    const res = await $nuxt.$axios.post( URL_EXCEL_FILE_TO_JSON, param, { timeout: 100000 } );
    // console.log( res );

    if ( $nuxt.$validate.isEmpty( res ) || $nuxt.$validate.isEmpty( res.data ) ) {

      result.error = '파일이 업로드 되지 않았습니다.';
      return result;
    }

    // 만일 확장자가 안맞아 에러가 발생했을 경우 서버에서 발생한 에러 리턴
    if ( res.data?.errorCode ) {

      result.error = res.data.message;
      return result;
    }

    // 등록된 사용자 정보가 없을 경우
    if ( $nuxt.$validate.isEmpty( res.data.data ) ) {
      result.error = '파일에 등록된 사용자 정보가 없습니다.';
      return result;
    }

    // 제한된 갯수보다 많이 등록했을 경우
    if ( options.limitCnt < res.data.data.length ) {

      result.error = `파일 업로드 시 등록 개수가 ${ options.limitCnt }개로 제한되어 있습니다.`;
      return result;
    }

    result.userData = res.data.data;
    return result;
  },

  /**
   * json을 객체에 맞는 구조로 변환합니다.
   * */
  convertJsonToWorkspaceUserList ( infoJson, orgUnitPath ) {

    // 변환된 객체 배열
    let listTemp = [];

    if ( $nuxt.$validate.isEmpty( infoJson ) || $nuxt.$validate.isEmpty( orgUnitPath ) ) {
      return listTemp;
    }

    infoJson.forEach( ( json, index ) => {
      listTemp.push(
        {
          primaryEmail: json[ 'Email Address [Required]' ].includes( '@' )
                      ? json[ 'Email Address [Required]' ] : `${json[ 'Email Address [Required]' ]}${process.env.GOOGLE_WORKSPACE_DOMAIN}`,
          givenName   : json[ 'First Name [Required]' ],
          familyName  : json[ 'Last Name [Required]' ],
          password    : json[ 'Password [Required]' ],
          orgUnitPath : orgUnitPath,
        },
      );

    } );

    return listTemp;

  },

  /**
   * json을 객체에 맞는 구조로 변환합니다.(배치 삭제 용도)
   * */
  convertJsonToWorkspaceBulkDeleteUserList ( infoJson ) {

    // 변환된 객체 배열
    let listTemp = [];
    infoJson.forEach( ( json, index ) => {
      listTemp.push(
        {
          fullName   : json[ '이름' ],
          primaryEmail: json[ '아이디' ],
          userType    : json[ '구분' ],
          userStatus    : json[ '상태' ],
          orgUnitPath    : json[ '소속' ],
          createDate    : json[ '생성일자' ],
        },
      );

    } );

    return listTemp;

  },

  /**
   * 구글 아이디가 비워져있는 것을 체크합니다.
   * true 반환 시 구글 아이디 비워져 있음.
   *
   * @param userInfoList
   * @return {*|boolean}
   */
  checkWorkspaceIdIsEmpty( userInfoList ) {

    if ( $nuxt.$validate.isEmpty( userInfoList ) ) {
      return true;
    }

    const emptyUserInfoList = userInfoList.filter( val => $nuxt.$validate.isEmpty( val.primaryEmail ) );
    return $nuxt.$validate.isNotEmpty( emptyUserInfoList );
  },

  /**
   * 엑셀로 등록된 메일 주소에 도메인 주소가 포함되어 있는지 검증합니다.
   * @param {Array} userInfoList
   * @return {Array}
   */
  workspaceDomainValidation ( userInfoList, domain ) {

    userInfoList.forEach( ( user, index ) => {

      // 이전 검증에서 잘못된 부분이 있었다면, 바로 실패시킵니다.
      if ( '' !== userInfoList[ index ].idAlert ) {
        return false;
      }

      const inputDomain = '@' + user.primaryEmail?.split( '@' )?.pop();
      if ( inputDomain !== domain ) {
        userInfoList[ index ].idAlert = PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_DOMAIN.KEY;
        userInfoList[ index ].available = false;
      }
      else {
        userInfoList[ index ].idAlert = '';
      }
    } );

    return userInfoList;
  },

  /**
   * 엑셀로 등록된 메일 주소의 아이디 형식이 적절한지 검증합니다.
   * @param {Array} userInfoList
   * @return {Array}
   */
  workspaceIdFormatValidation ( userInfoList, schoolCode, accountTypeRadio ) {

    userInfoList.forEach( ( user, index ) => {

      // 이전 검증에서 잘못된 부분이 있었다면, 바로 실패시킵니다.
      if ( '' !== userInfoList[ index ].idAlert ) {
        return false;
      }

      const primaryEmailArray = user.primaryEmail?.split( '@' );
      primaryEmailArray?.pop();
      // abc@def@올바른도메인 과 같은 주소가 존재할 수 있으므로, 다시 합쳐 계산합니다.
      let primaryEmail = primaryEmailArray?.join( '' );
      // 입력값을 대문자로 변환하여, 검증합니다.
      primaryEmail = primaryEmail.toUpperCase();

      // 아이디의 첫번째가 schoolCode인지 검증합니다.
      if ( 0 !== primaryEmail.indexOf( schoolCode ) ) {
        userInfoList[ index ].idAlert = PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_ID_FORMAT.KEY;
        userInfoList[ index ].available = false;
        return false;
      }

      // schoolCode에 이어 바로 올바른 라디오 타입인지 검증합니다.
      const mailAddressWithoutSchoolCode = primaryEmail.replace( schoolCode, '' );
      if ( 0 !== mailAddressWithoutSchoolCode.indexOf( accountTypeRadio ) ) {
        userInfoList[ index ].idAlert = PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_ID_FORMAT.KEY;
        userInfoList[ index ].available = false;
        return false;
      }

      // 남은 문자열이 5자리의 숫자인지 검증합니다.
      const remainingString = mailAddressWithoutSchoolCode.replace( accountTypeRadio, '' );
      if ( isNaN( remainingString ) || 5 !== remainingString.length ) {
        userInfoList[ index ].idAlert = PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.WRONG_ID_FORMAT.KEY;
        userInfoList[ index ].available = false;
        return false;
      }

      userInfoList[ index ].idAlert = '';
    } );

    return userInfoList;
  },

  /**
   * 현재 리스트 내에서 중복된 메일이 있는지 파악합니다.
   * @param {Array} userInfoList
   * @return {Array}
   */
  workspaceDuplicateValidationInList ( userInfoList ) {

    const duplicateCheck = {};
    userInfoList.forEach( ( user, index ) => {
      // 이전 검증에서 잘못된 부분이 있었다면, 바로 실패시킵니다.
      if ( '' !== userInfoList[ index ].idAlert ) {
        return false;
      }

      if ( 0 === duplicateCheck[ user.primaryEmail.toUpperCase() ] ) {
        userInfoList[ index ].idAlert = PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.DUPLICATION_IN_LIST.KEY;
        userInfoList[ index ].available = false;
      }
      else {
        duplicateCheck[ user.primaryEmail.toUpperCase() ] = 0;
        userInfoList[ index ].idAlert = '';
      }
    } );

    return userInfoList;
  },

  /* id 중복 검사
* 구글 워크스페이스에 해당 아이디가 이미 존재하는지 확인한다.
* */
  async workspaceDuplicateIdValidation ( userInfoList, orgUnitPath ) {

    try {

      userInfoList.forEach( el => {
        el.primaryEmail = el.primaryEmail?.replaceAll( ' ', '' ); // 공백 제거
      } );

      const primaryEmailList = userInfoList.map( el => el.primaryEmail );

      const param = {
        userKeyList: primaryEmailList,
        query      : `orgUnitPath='${ orgUnitPath }'`,
      };

      // 시간이 짧아서 확인할 id가 많으면 axios 끊어져서 timeout 을 좀 더 늘려줌.(옵션의 최대 생성 갯수인 50개 유효성 검사 할 수 있음.)
      const res = await $nuxt.$googleDirectory.userIdExistenceCheckBulk( param );
      if ( res ) {
        res.data.forEach( ( el, idx, arr ) => {
          if ( el === false && '' === userInfoList[ idx ].idAlert ) {
            userInfoList[ idx ].idAlert = PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.AVAILABLE.KEY;
            userInfoList[ idx ].available = true;
          }
          else if ( el === true ) {
            userInfoList[ idx ].idAlert = PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.DUPLICATION.KEY;
            userInfoList[ idx ].available = false;
          }
        } );
      }
      return userInfoList;
    }
    catch ( e ) {
      console.error( e );
    }
    return userInfoList;
  },

  // 구글워크스페이스 사용자 등록 시 사용자 리스트의 orgUnitPath 경로를 변경합니다.
  updateOrgUnitPathByUserList( userList, orgUnitPath ) {

    if ( $nuxt.$validate.isEmpty( userList ) || $nuxt.$validate.isEmpty( orgUnitPath ) ) {
      return [];
    }

    return userList.forEach( user => {
      user.orgUnitPath = orgUnitPath;
    })
  },

  // 구글 시간을 화면에 맞게 반환합니다.
  clacGoogleTime( time ) {
    let timeDiff = 1000 * 60 * 60 * 9;
    return (time === 0) ? '-' : $nuxt.$util.dateUtils.formatDateTimeMin( new Date( time
      + timeDiff ).toISOString() );
  },

};

export default portalGoogleWorkspace;
